import React, { useEffect, useState } from "react"
import RiveLoader from "../RiveLoader/RiveLoader"

const IntegrationWheel = props => {
  const { dataOne, dataTwo } = props
  const [showIntegwheel, setShowIntegWheel] = useState(false)
  const [riveLoad, setRiveLoad] = useState()
  const [rive, setRive] = useState()

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const integWheel = document.getElementById("integ_wheel_id")
    if (integWheel?.getBoundingClientRect()?.top < window.innerHeight / 2) {
      setShowIntegWheel(true)
    }
  }

  useEffect(() => {
    handleVisibility()
  }, [riveLoad])

  // const onRiveEventReceived = riveEvent => {
  //   const eventData = riveEvent.data
  //   if (
  //     (eventData.name === "ButtonHover_01" ||
  //       eventData.name === "ButtonHover_02" ||
  //       eventData.name === "ButtonHover_03") &&
  //     eventData.properties["isHovering"]
  //   ) {
  //     document.body.style.cursor = "pointer"
  //   } else {
  //     document.body.style.cursor = "auto"
  //   }
  // }

  // useEffect(() => {
  //   if (rive) {
  //     rive.on(EventType.RiveEvent, onRiveEventReceived)
  //   }
  // }, [rive])
  return (
    <section
      id="integ_wheel_id"
      className="integration-wheel Layout-container-big"
    >
      <div className="wheel-height">
        {showIntegwheel ? (
          <RiveLoader
            setRive={setRive}
            stateMachines="Arc_State"
            file={dataOne}
            className="rive-board"
            callbackFn={() => setRiveLoad(true)}
          />
        ) : null}
      </div>
    </section>
  )
}

export default IntegrationWheel
